<template>
  <draggable
    :list="widgets"
    item-key="name"
    @end="emitData"
  >
    <template #item="{element}">
      <div class="button-block">
        <div class="button-block__title">
          <el-icon :size="15" class="button-block__title-icon">
            <DCaret />
          </el-icon>
          {{ TranslateList[0][element.name] || '???' }}
        </div>
        <div class="button-block__check">
          <el-switch
            v-model="element.visible"
            size="small"
            @change="emitData"
          />
        </div>
      </div>
    </template>
  </draggable>
</template>

<script lang="ts">
import {
  defineComponent, PropType, reactive, watch, 
} from 'vue';
import Draggable from 'vuedraggable-es';
import { DCaret } from '@element-plus/icons-vue';
import { TranslateList } from '../../../../../requests';

export default defineComponent({
  name: 'GroupContacts',
  components: { Draggable, DCaret },
  props: {
    data: {
      type: Object as PropType<any>,
      required: true,
    },
  },
  emits: ['update:data'],
  setup (props, { emit }) {
    const state = reactive(props.data);

    const widgets = reactive([
      {
        hasPrivacy: true,
        infoText: 'lang.phone_visibility',
        isShow: 'data.hidePhone',
        name: 'phone',
        text: 'data.contacts.phone',
        title: 'lang.phone',
        type: 'info_line_action_widget',
        visible: false,
      },
      {
        leftIcon: null,
        leftText: 'lang.telegram',
        name: 'telegram',
        rightIcon: 'next',
        rightText: 'data.contacts.telegram',
        type: 'list_line_widget',
        visible: false,
      },
    ]);

    watch(() => props.data, () => {
      const visibleButtons = props.data.widgets?.map((w: Button) => (w.name));
      widgets.map((i) => {
        i.visible = visibleButtons.includes(i.name);
        return i;
      });
    }, { deep: true, immediate: true });

    const emitData = () => {
      const out = widgets.filter((i) => i.visible).map(({ visible, ...w }) => w);

      emit('update:data', out);
    };

    return {
      state,
      widgets,
      emitData,

      TranslateList,
    };
  },
});
</script>

<style scoped>
.button-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: var(--el-box-shadow-lighter);
  padding: 10px 20px;
  border-radius: var(--el-card-border-radius);
  margin-bottom: 10px;

  &__title {
    display: flex;
    align-items: center;
  }
  &__title-icon {
    cursor: move;
    margin-right: 5px;
  }
}
</style>
