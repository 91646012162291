<template>
  <div class="editable-text">
    <div v-if="!isEdit" class="editable-text__text">
      <div>{{ state }}</div>
      <div>
        <el-button
          type="primary"
          :icon="EditPen"
          link
          @click.stop="isEdit = true"
        />
      </div>
    </div>
    <div
      v-else
      class="editable-text__input"
    >
      <el-input
        v-model="state"
        @click.stop
        @keydown.enter.stop="applyHandler"
        @keydown.space.prevent="stopPropagation"
      />
      <div>
        <el-button
          type="primary"
          :icon="Close"
          link
          @click.stop="applyHandler"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { EditPen, Close } from '@element-plus/icons-vue';
import { TranslateList } from 'features/settings/mobile-editor/requests';

export default defineComponent({
  name: 'EditableText',
  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const isEdit = ref(false);
    const state = ref(TranslateList[0][props.modelValue?.replace('lang.', '')] || props.modelValue);

    const applyHandler = () => {
      if (!state.value) return;

      emit('update:modelValue', state.value);
      isEdit.value = false;
    };

    /**
     * Этот костыль для того, что бы обойти раскрытие коллапса при нажатии на пробел.
     * т.к. @keydown.space.stop не срабатывает на событие пробел
     */
    const stopPropagation = () => {
      state.value += ' ';
    };

    return {
      state,
      isEdit,
      EditPen,
      Close,
      applyHandler,
      stopPropagation,
    };
  },
});
</script>

<style scoped>
.editable-text {
  width: 89%;
  display: flex;
  align-items: baseline;
  gap: 10px;
}
.editable-text__text, .editable-text__input {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 10px;
}

.editable-text .el-button.el-button--primary {
  padding-right: 10px;
}

.card-form .el-button {
  margin: 0;
}
</style>
