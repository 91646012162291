import loginPageConfig from 'features/login/login';
import residentsConfig from 'features/residents/residents/udschool';
import eventConfig from 'features/events/event/udschool';
import eventGuestsConfig from 'features/events/guests/guests/hubstr';
import eventsConfig from 'features/events/events/hubstr';
import addressConfig from 'features/addresses/address/hubstr';
import addressesConfig from 'features/addresses/addresses/hubstr';
import eventCategoriesConfig from 'features/settings/event-categories/categories/hubstr';
import { translate } from 'magner';
import complaintsConfig from 'features/settings/complaints/complaints/hubstr';
import bansConfig from 'features/settings/bans/bans/hubstr';
import authListConfig from 'features/settings/authorization/list';

// import clubsInClubConfig from 'features/settings/club-in-club/clubs/hubstr';
// import partnersConfig from 'features/settings/partners/partners/hubstr';

export const udschoolRouterConfig = {
  global: {
    homeNoAuthName: 'login',
    homeHasAuthName: 'residents',
  },

  routes: [
    {
      type: 'custom',
      route: {
        name: 'vimeo',
        path: '/domen/video/:id',
        component: () => import('features/vimeo/vimeo.vue'),
        roles: false,
      },
    },
    {
      type: 'custom',
      route: {
        name: 'home',
        path: '/',
        component: () => import('configs/empty.vue'),
        roles: false,
      },
    },

    {
      type: 'preset',
      route: {
        path: '/login',
        name: 'login',
      },
      preset: {
        preset: 'login',
        config: loginPageConfig,
      },
    },

    {
      type: 'layout',
      layout: {
        layout: 'main',
        name: '',
        path: '/',
        props: {
          headerIcon: () => import('assets/udschool/icons/logo-not-collapsed.svg'),
          headerCollapsedIcon: () => import('assets/udschool/icons/logo-collapsed.svg'),

          sidebarGroups: [
            {
              name: 'settings-group',
              icon: () => import('assets/icons/settings.svg'),
              title: translate('hubstr.login.sidebar.group_title'),
              routes: [
                'event-categories',
                // 'club-in-club',
                // 'partners',
              ],
            },
            {
              name: 'system-group',
              icon: () => import('assets/icons/system.svg'),
              title: translate('hubstr.system.sidebar.group_title'),
              routes: [
                'auth-list',
              ],
            },
          ],
        },
        routes: [
          /** Residents - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: residentsConfig,
            },
            route: {
              name: 'residents',
              path: '/residents',
              roles: true,
              visible: true,
              icon: () => import('assets/icons/user.svg'),
              title: translate('hubstr.resident.sidebar.table_title'),
            },
          },
          /** Events - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: eventsConfig,
            },
            route: {
              name: 'events',
              path: '/events',
              roles: true,
              visible: true,
              icon: () => import('assets/icons/map-pin.svg'),
              title: translate('hubstr.event.sidebar.table_title'),
            },
          },
          /** Events - card */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: eventConfig,
            },
            route: {
              name: 'event',
              path: '/events/:id',
              link: '/events/new',
              roles: true,
              visible: false,
              title: translate('hubstr.event.sidebar.card_title'),
            },
          },
          /** Events guests - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: eventGuestsConfig,
            },
            route: {
              name: 'event-guests',
              path: '/events/:id/guests',
              roles: true,
              visible: false,
              title: translate('hubstr.event.guests.sidebar.table_title'),
            },
          },
          /** Addresses - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: addressesConfig,
            },
            route: {
              name: 'addresses',
              path: '/addresses',
              roles: true,
              visible: true,
              icon: () => import('assets/icons/map.svg'),
              title: translate('hubstr.address.sidebar.table_title'),
            },
          },
          /** Addresses - card */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: addressConfig,
            },
            route: {
              name: 'address',
              path: '/addresses/:id',
              link: '/addresses/new',
              roles: true,
              visible: false,
              title: translate('hubstr.address.sidebar.card_title'),
            },
          },
          /** Settings block */
          /** Business areas - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: eventCategoriesConfig,
            },
            route: {
              name: 'event-categories',
              path: '/settings/event-categories',
              roles: true,
              visible: true,
              title: translate('hubstr.event_category.sidebar.table_title'),
            },
          },
          /** Auth list - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: authListConfig,
            },
            route: {
              name: 'auth-list',
              path: '/auth-list',
              roles: true,
              visible: true,
              title: translate('hubstr.auth.sidebar.table_title'),
            },
          },
          /** Complaints - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: complaintsConfig,
            },
            route: {
              name: 'complaints',
              path: '/complaints',
              roles: true,
              visible: true,
              icon: () => import('assets/icons/user.svg'),
              title: translate('hubstr.complaints.sidebar.table_title'),
            },
          },
          /** Bans - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: bansConfig,
            },
            route: {
              name: 'bans',
              path: '/bans',
              roles: true,
              visible: true,
              icon: () => import('assets/icons/user.svg'),
              title: translate('hubstr.bans.sidebar.table_title'),
            },
          },
        ],
      },
    },

    {
      type: 'preset',
      route: {
        path: '/:pathMatch(.*)*',
        name: 'error',
      },
      preset: {
        preset: '404',
        config: {},
      },
    },
  ],
};
