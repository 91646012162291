import loginPageConfig from 'features/login/login';
import residentsConfig from 'features/residents/residents/hubstr';
import residentConfig from 'features/residents/resident/code-pilots';
import companiesConfig from 'features/residents/companies/companies/hubstr';
import companyConfig from 'features/residents/companies/company/hubstr';
import eventConfig from 'features/events/event/hubstr';
import eventGuestsConfig from 'features/events/guests/guests/hubstr';
import eventsConfig from 'features/events/events/hubstr';
import addressConfig from 'features/addresses/address/hubstr';
import addressesConfig from 'features/addresses/addresses/hubstr';
import eventCategoriesConfig from 'features/settings/event-categories/categories/hubstr';
import clubRolesConfig from 'features/settings/club-roles/roles/hubstr';
import interviewsConfig from 'features/settings/interviews/interviews';
import interviewConfig from 'features/settings/interviews/interview';
import { translate } from 'magner';
import authListConfig from 'features/settings/authorization/list';

export const pilotsRouterConfig = {
  global: {
    homeNoAuthName: 'login',
    homeHasAuthName: 'residents',
  },

  routes: [
    {
      type: 'custom',
      route: {
        name: 'vimeo',
        path: '/domen/video/:id',
        component: () => import('features/vimeo/vimeo.vue'),
        roles: false,
      },
    },
    {
      type: 'custom',
      route: {
        name: 'home',
        path: '/',
        component: () => import('configs/empty.vue'),
        roles: false,
      },
    },

    {
      type: 'preset',
      route: {
        path: '/login',
        name: 'login',
      },
      preset: {
        preset: 'login',
        config: loginPageConfig,
      },
    },

    {
      type: 'layout',
      layout: {
        layout: 'main',
        name: '',
        path: '/',
        props: {
          headerIcon: () => import('assets/code_pilots/icons/logo-not-collapsed.svg'),
          headerCollapsedIcon: () => import('assets/code_pilots/icons/logo-collapsed.svg'),

          sidebarGroups: [
            {
              name: 'settings-group',
              icon: () => import('assets/icons/settings.svg'),
              title: translate('hubstr.login.sidebar.group_title'),
              routes: [
                'event-categories',
                'club-roles',
                'interviews',
              ],
            },
            {
              name: 'system-group',
              icon: () => import('assets/icons/system.svg'),
              title: translate('hubstr.system.sidebar.group_title'),
              routes: [
                'auth-list',
              ],
            },
          ],
        },
        routes: [
          /** Residents - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: residentsConfig,
            },
            route: {
              name: 'residents',
              path: '/residents',
              roles: true,
              visible: true,
              icon: () => import('assets/icons/user.svg'),
              title: translate('hubstr.resident.sidebar.table_title'),
            },
          },
          /** Residents - card */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: residentConfig,
            },
            route: {
              name: 'resident',
              path: '/residents/:id',
              link: '/residents/new',
              roles: true,
              visible: false,
              icon: () => import('assets/icons/user.svg'),
              title: translate('hubstr.resident.sidebar.card_title'),
            },
          },
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: companiesConfig,
            },
            route: {
              name: 'resident-companies',
              path: '/residents/:id/companies',
              roles: true,
              visible: false,
              title: translate('hubstr.resident.sidebar.companies_title'),
            },
          },
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: companyConfig,
            },
            route: {
              name: 'company',
              path: '/companies/:id',
              roles: true,
              visible: false,
              title: translate('hubstr.resident.sidebar.company_title'),
            },
          },

          /** Events */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: eventConfig,
            },
            route: {
              name: 'event',
              path: '/events/:id',
              link: '/events/new',
              roles: true,
              visible: false,
              title: translate('hubstr.event.sidebar.card_title'),
            },
          },
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: eventGuestsConfig,
            },
            route: {
              name: 'event-guests',
              path: '/events/:id/guests',
              roles: true,
              visible: false,
              title: translate('hubstr.event.guests.sidebar.table_title'),
            },
          },
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: eventsConfig,
            },
            route: {
              name: 'events',
              path: '/events',
              roles: true,
              visible: true,
              icon: () => import('assets/icons/map-pin.svg'),
              title: translate('hubstr.event.sidebar.table_title'),
            },
          },

          /** Addresses */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: addressConfig,
            },
            route: {
              name: 'address',
              path: '/addresses/:id',
              link: '/addresses/new',
              roles: true,
              visible: false,
              title: translate('hubstr.address.sidebar.card_title'),
            },
          },
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: addressesConfig,
            },
            route: {
              name: 'addresses',
              path: '/addresses',
              roles: true,
              visible: true,
              icon: () => import('assets/icons/map.svg'),
              title: translate('hubstr.address.sidebar.table_title'),
            },
          },

          /** Settings block */
          /** Event categories */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: eventCategoriesConfig,
            },
            route: {
              name: 'event-categories',
              path: '/settings/event-categories',
              roles: true,
              visible: true,
              title: translate('hubstr.event_category.sidebar.table_title'),
            },
          },

          /** Club roles */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: clubRolesConfig,
            },
            route: {
              name: 'club-roles',
              path: '/settings/club-roles',
              roles: true,
              visible: true,
              title: translate('hubstr.club_role.sidebar.table_title'),
            },
          },
          /** Auth list - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: authListConfig,
            },
            route: {
              name: 'auth-list',
              path: '/auth-list',
              roles: true,
              visible: true,
              title: translate('hubstr.auth.sidebar.table_title'),
            },
          },

          /** Interviews */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: interviewsConfig,
            },
            route: {
              name: 'interviews',
              path: '/settings/interviews',
              roles: true,
              visible: true,
              title: translate('gaz.interview.sidebar.table_title'),
            },
          },
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: interviewConfig,
            },
            route: {
              name: 'interview',
              path: '/settings/interviews/:id',
              link: '/settings/interviews/new',
              roles: true,
              visible: false,
              title: translate('gaz.interview.sidebar.card_title'),
            },
          },
        ],
      },
    },

    {
      type: 'preset',
      route: {
        path: '/:pathMatch(.*)*',
        name: 'error',
      },
      preset: {
        preset: '404',
        config: {},
      },
    },
  ],
};
