import loginPageConfig from 'features/login/login';
import residentsConfig from 'features/residents/residents/hubstr';
import complaintsConfig from 'features/settings/complaints/complaints/hubstr';
import bansConfig from 'features/settings/bans/bans/hubstr';

import residentConfig from 'features/residents/resident/meta-hubstr';
import companiesConfig from 'features/residents/companies/companies/hubstr';
import companyConfig from 'features/residents/companies/company/hubstr';
import eventConfig from 'features/events/event/hubstr';
import eventGuestsConfig from 'features/events/guests/guests/hubstr';
import eventsConfig from 'features/events/events/hubstr';
import addressConfig from 'features/addresses/address/hubstr';
import addressesConfig from 'features/addresses/addresses/hubstr';
import eventCategoriesConfig from 'features/settings/event-categories/categories/hubstr';
import clubRolesConfig from 'features/settings/club-roles/roles/hubstr';
import linkGroupsConfig from 'features/settings/link-groups/groups/hubstr';
import linksConfig from 'features/settings/link-groups/links/hubstr';
import mobileEditorConfig from 'features/settings/mobile-editor/mobile-editor';
import reportsUserActivityConfig from 'features/reports/user-activity/table';
import reportsEventsConfig from 'features/reports/events/table';
import { translate } from 'magner';
import authListConfig from 'features/settings/authorization/list';
import preferenceConfig from 'features/settings/preferences/table/hubstr';
import changeLogConfig from 'features/change-log/meta/meta-hubstr';
import { APP_SETTINGS_EDITOR_ON } from '~/constants';

export const metaHubstrRouterConfig = {
  global: {
    homeNoAuthName: 'login',
    homeHasAuthName: 'residents',
  },

  routes: [
    {
      type: 'custom',
      route: {
        name: 'vimeo',
        path: '/admin/domen/video/:id',
        component: () => import('features/vimeo/vimeo.vue'),
        roles: false,
      },
    },
    {
      type: 'custom',
      route: {
        name: 'home',
        path: '/admin/',
        component: () => import('configs/empty.vue'),
        roles: false,
      },
    },

    {
      type: 'preset',
      route: {
        path: '/admin/login',
        name: 'login',
      },
      preset: {
        preset: 'login',
        config: loginPageConfig,
      },
    },

    {
      type: 'layout',
      layout: {
        layout: 'main',
        name: '',
        path: '/admin/',
        props: {
          headerIcon: () => import('assets/hubstr/icons/logo-not-collapsed.svg'),
          headerCollapsedIcon: () => import('assets/hubstr/icons/logo-collapsed.svg'),

          sidebarGroups: [
            {
              name: 'catalogs-group',
              icon: () => import('assets/icons/book.svg'),
              title: translate('hubstr.login.sidebar.group_title'),
              routes: [
                'event-categories',
                'addresses',
                'club-roles',
              ],
            },
            {
              name: 'system-group',
              icon: () => import('assets/icons/settings.svg'),
              title: translate('hubstr.system.sidebar.group_title'),
              routes: [
                'mobile-editor',
                'link-groups',
                'auth-list',
                'preferences',
              ],
            },
            {
              name: 'moderation-group',
              icon: () => import('assets/icons/user.svg'),
              title: translate('hubstr.moderation.sidebar.group_title'),
              routes: [
                'complaints',
                'bans',
              ],
            },
            {
              name: 'reports-group',
              icon: () => import('assets/icons/reports.svg'),
              title: translate('hubstr.reports.sidebar.title'),
              routes: [
                'report-user-activity',
                'report-events',
              ],
            },
          ],
        },
        routes: [
          /** Residents - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: residentsConfig,
            },
            route: {
              name: 'residents',
              path: '/admin/residents',
              roles: true,
              visible: true,
              icon: () => import('assets/icons/users.svg'),
              title: translate('hubstr.resident.sidebar.table_title'),
            },
          },
          /** Residents - card */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: residentConfig,
            },
            route: {
              name: 'resident',
              path: '/admin/residents/:id',
              link: '/admin/residents/new',
              roles: true,
              visible: false,
              icon: () => import('assets/icons/user.svg'),
              title: translate('hubstr.resident.sidebar.card_title'),
            },
          },
          /** Events - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: eventsConfig,
            },
            route: {
              name: 'events',
              path: '/admin/events',
              roles: true,
              visible: true,
              icon: () => import('assets/icons/map-pin.svg'),
              title: translate('hubstr.event.sidebar.table_title'),
            },
          },
          /** Events - card */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: eventConfig,
            },
            route: {
              name: 'event',
              path: '/admin/events/:id',
              link: '/admin/events/new',
              roles: true,
              visible: false,
              title: translate('hubstr.event.sidebar.card_title'),
            },
          },
          /** Events - guests */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: eventGuestsConfig,
            },
            route: {
              name: 'event-guests',
              path: '/admin/events/:id/guests',
              roles: true,
              visible: false,
              title: translate('hubstr.event.guests.sidebar.table_title'),
            },
          },

          /** Catalogs group */
          /** Event categories */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: eventCategoriesConfig,
            },
            route: {
              name: 'event-categories',
              path: '/admin/settings/event-categories',
              roles: true,
              visible: true,
              title: translate('hubstr.event_category.sidebar.table_title'),
            },
          },
          /** Addresses - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: addressesConfig,
            },
            route: {
              name: 'addresses',
              path: '/admin/addresses',
              roles: true,
              visible: true,
              title: translate('hubstr.address.sidebar.table_title'),
            },
          },
          /** Addresses - card */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: addressConfig,
            },
            route: {
              name: 'address',
              path: '/admin/addresses/:id',
              link: '/admin/addresses/new',
              roles: true,
              visible: false,
              title: translate('hubstr.address.sidebar.card_title'),
            },
          },
          /** Reports block */
          /** Reports - user activity */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: reportsUserActivityConfig,
            },
            route: {
              name: 'report-user-activity',
              path: '/admin/reports/user-activity',
              roles: true,
              visible: true,
              title: translate('hubstr.reports.sidebar.user_activity_title'),
            },
          },
          /** Reports - events */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: reportsEventsConfig,
            },
            route: {
              name: 'report-events',
              path: '/admin/reports/events',
              roles: true,
              visible: true,
              title: translate('hubstr.reports.sidebar.events_title'),
            },
          },
          /** Club roles */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: clubRolesConfig,
            },
            route: {
              name: 'club-roles',
              path: '/admin/settings/club-roles',
              roles: true,
              visible: true,
              title: translate('hubstr.club_role.sidebar.table_title'),
            },
          },

          /** System group */
          /** Edit mobile profile */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: mobileEditorConfig,
            },
            route: {
              name: 'mobile-editor',
              path: '/admin/settings/mobile-editor',
              roles: true,
              visible: APP_SETTINGS_EDITOR_ON,
              title: translate('hubstr.mobile_profile.sidebar.title'),
            },
          },
          /** Link groups - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: linkGroupsConfig,
            },
            route: {
              name: 'link-groups',
              path: '/admin/settings/link-groups',
              roles: true,
              visible: true,
              title: translate('hubstr.link_groups.sidebar.table_title'),
            },
          },
          /** Links - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: linksConfig,
            },
            route: {
              name: 'links',
              path: '/admin/settings/links/:id',
              roles: true,
              visible: false,
              title: translate('hubstr.link_groups.sidebar.table_title'),
            },
          },
          /** Auth list - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: authListConfig,
            },
            route: {
              name: 'auth-list',
              path: '/admin/auth-list',
              roles: true,
              visible: true,
              title: translate('hubstr.auth.sidebar.table_title'),
            },
          },

          /** Moderation group */
          /** Complaints - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: complaintsConfig,
            },
            route: {
              name: 'complaints',
              path: '/admin/complaints',
              roles: true,
              visible: true,
              title: translate('hubstr.complaints.sidebar.table_title'),
            },
          },
          /** Bans - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: bansConfig,
            },
            route: {
              name: 'bans',
              path: '/admin/bans',
              roles: true,
              visible: true,
              title: translate('hubstr.bans.sidebar.table_title'),
            },
          },

          /** Preferences - card */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: preferenceConfig,
            },
            route: {
              name: 'preferences',
              path: '/admin/settings/preferences',
              roles: true,
              visible: true,
              title: translate('hubstr.preferences.sidebar.title'),
            },
          },

          /** Companies - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: companiesConfig,
            },
            route: {
              name: 'resident-companies',
              path: '/admin/residents/:id/companies',
              roles: true,
              visible: false,
              title: translate('hubstr.resident.sidebar.companies_title'),
            },
          },
          /** Companies - card */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: companyConfig,
            },
            route: {
              name: 'company',
              path: '/admin/companies/:id',
              roles: true,
              visible: false,
              title: translate('hubstr.resident.sidebar.company_title'),
            },
          },
          /** Change Log */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: changeLogConfig,
            },
            route: {
              name: 'change-log',
              path: '/admin/change-log',
              roles: true,
              visible: false,
              title: 'change-log',
            },
          },
        ],
      },
    },

    {
      type: 'preset',
      route: {
        path: '/admin/:pathMatch(.*)*',
        name: 'error',
      },
      preset: {
        preset: '404',
        config: {},
      },
    },
  ],
};
