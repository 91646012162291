<template>
  <div class="social-btn__row">
    <div v-for="widget in data.widgets" :key="widget.name" class="social-btn__col">
      <div class="social-btn">
        <div class="social-btn__icon">
          <component :is="widget.name" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent, PropType,
} from 'vue';
import { Body } from 'features/settings/mobile-editor/interfaces';
import Telegram from '../assets/icons/telegram.svg';
import Vk from '../assets/icons/vk.svg';
import Instagram from '../assets/icons/instagram.svg';
import Facebook from '../assets/icons/facebook.svg';

export default defineComponent({
  name: 'SocialButtons',
  components: {
    Telegram, Vk, Instagram, Facebook,
  },
  props: {
    data: {
      type: Object as PropType<Body[]>,
    },
  },
});
</script>

<style scoped>
.social-btn__row {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-bottom: 20px;
}

.social-btn__col {
  box-sizing: border-box;
  padding-right: 10px;
}

.social-btn {
  display: flex !important;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.social-btn__icon {
  height: 35px;
  width: 35px;
  background-color: #007AFF;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  & > svg {
    width: 16px;
  }
}
</style>
