// @ts-ignore
import { cardPageController, translate } from 'magner';
import {
  mobileProfileGet, mobileProfileUpdate,
} from './requests';
import EditMobileProfileLayout from './components/layout/index.vue';

const mobileEditorConfig = cardPageController({
  getRequest: mobileProfileGet,
  createRequest: '',
  updateRequest: mobileProfileUpdate,
  deleteRequest: '',

  header: {
    title: translate('hubstr.mobile_profile.form.title'),
  },

  form: {
    fullDataOnUpdate: true,
    actions: [
      {
        type: 'action',
        emits: 'cancel',
        props: {
          text: translate('gaz.interview.form.cancel_button'),
          class: 'cancel',
        },
      },
      {
        type: 'action',
        emits: 'submit',
        props: {
          type: 'primary',
          text: translate('gaz.interview.form.submit_button'),
        },
      },
    ],
    layout: [
      {
        type: 'custom',
        name: 'editMobileProfileLayout',
        component: () => EditMobileProfileLayout,
        props: {},
      },
    ],
  },
});

export default mobileEditorConfig;
