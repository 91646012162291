<template>
  <div class="container text-section">
    <div class="draggable_icon">
      <el-icon class="text-section__move-icon"><DCaret /></el-icon>
    </div>
    <div class="type">
      <el-form-item label="Тип поля">
        <el-input v-model="EnumFieldTypes[state.type]" disabled />
      </el-form-item>
    </div>
    <div class="label">
      <el-form-item label="Лейбл">
        <el-input v-if="state.type === 'text_section_widget'" disabled :value="TranslateList[0][state.title.replace('lang.', '')] || state.title || matchingField?.description" />
        <el-input v-if="state.type === 'array_section_widget'" disabled :value="TranslateList[0][state.title.replace('lang.', '')] || state.title || matchingField?.description" />
        <el-input v-if="state.type === 'info_line_action_widget'" disabled :value="TranslateList[0][state.title.replace('lang.', '')] || state.title" />
        <el-input v-if="state.type === 'list_line_widget'" disabled :value="TranslateList[0][state.leftText.replace('lang.', '')] || state.leftText || matchingField?.description" />
        <el-input v-if="state.type === 'list_line_raw_action_widget'" disabled :value="TranslateList[0][state.leftText.replace('lang.', '')] || state.leftText || matchingField?.description" />
      </el-form-item>
    </div>
    <div class="required_info">
      <div v-if="matchingField?.isAdminRequired"><span>* </span>Обязательно для админ-панели</div>
      <div v-if="matchingField?.isUserRequired"><span>* </span>Обязательно для мобильного приложения</div>
      <div v-if="data?.hasPrivacy"><span>* </span>Имеет настройки приватности</div>
    </div>
    <div class="action_btn">
      <el-button
        type="primary"
        link
        :icon="EditPen"
        @click="editModal"
      />
      <el-popconfirm
        title="Уверены что хотите удалить поле?"
        :width="300"
        @confirm="removeField(state.id)"
      >
        <template #reference>
          <el-button
            type="danger"
            link
            :icon="Delete"
          />
        </template>
      </el-popconfirm>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, reactive } from 'vue';
import { AdditionalField, WidgetBody } from 'features/settings/mobile-editor/interfaces';
import { DCaret, Delete, EditPen } from '@element-plus/icons-vue';
import { EnumFieldTypes } from 'features/settings/mobile-editor/constants';
import { TranslateList } from 'features/settings/mobile-editor/requests';

export default defineComponent({
  name: 'Field',
  components: {
    DCaret,
  },
  props: {
    data: {
      type: Object as PropType<WidgetBody>,
      required: true,
    },
    translate: {
      type: Object as PropType<any>,
      required: true,
    },
    additionalList: {
      type: Object as PropType<any>,
      required: true,
    },
  },
  emits: ['remove', 'edit'],
  setup (props, { emit }) {
    const state = reactive(props.data);

    const removeField = (id: string) => {
      emit('remove', id);
    };

    const editModal = (id: string) => {
      emit('edit', id);
    };

    const matchingField = Object.values(props.additionalList[0]).find((field) => Object.keys(props.data).some((key) => {
      if (props.data[key] && typeof props.data[key] === 'string') {
        return props.data[key]?.includes(field.formCode);
      }
    })) as AdditionalField;

    return {
      EnumFieldTypes,
      Delete,
      EditPen,
      editModal,
      state,
      matchingField,
      removeField,
      TranslateList,
    };
  },
});
</script>

<style lang="scss" scoped>
.text-section {
  &__move-icon {
    cursor: move;
  }
}

.container {
  background-color: white;
  border: 1px #f5f5f5 solid;
  border-radius: 5px;
  padding: 15px;
  display: grid;
  margin-bottom: 10px;
  grid-template-columns: 0.1fr 1fr 1fr 0.1fr;
  grid-template-rows: auto auto;
  gap: 10px;
  grid-auto-flow: row;
  align-items: stretch;
  grid-template-areas:
    "draggable_icon label type action_btn"
    "draggable_icon required_info required_info action_btn";

  .el-form-item {
    margin-bottom: 0 !important;
  }
}

.draggable_icon {
  margin-top: 30px;
  grid-area: draggable_icon;
}

.action_btn {
  margin-top: 20px;
  grid-area: action_btn;
  display: flex;
  gap: 10px;
}

.required_info {
  color: var(--el-text-color-secondary);
  font-weight: lighter;
  grid-area: required_info;

  span {
    color: var(--el-color-danger)
  }
}

.type { grid-area: type; }

.label { grid-area: label; }

</style>
