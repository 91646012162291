<template>
  <div class="info-line-action">
    <label for="" class="info-line-action__label">{{ translate[data.title.replace('lang.', '')] || data.title }}</label>
    <div class="info-line-action__text">+79999999999</div>
  </div>
  <el-divider />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

interface InfoLineAction {
  name: string
  text: string
  type: string
  title: string
  isShow: string
  infoText: string
  id: string
}

export default defineComponent({
  name: 'InfoLineAction',
  props: {
    data: {
      type: Object as PropType<InfoLineAction>,
      required: true,
    },
    translate: {
      type: Object as PropType<any>,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.info-line-action {
  display: flex;
  justify-content: space-between;
  &__text {
    color: var(--el-color-primary)
  }
}
.el-divider--horizontal {
  margin: 15px 0 15px 0;
}
</style>