<template>
  <div class="phone-wrap">
    <div class="iphone-x">
      <i>Speaker</i>
      <b>Camera</b>
      <div class="brow">
        <s>{{ new Date().toLocaleTimeString([], {timeStyle: 'short'}) }}</s>
      </div>
      <div class="content">
        <div class="content__switch">
          <el-button type="text" @click="switchContent = !switchContent">
            <div v-if="!switchContent" class="content__switch-icon">
              <ArrowLeft />
            </div>
          </el-button>
          <el-button type="text" @click="switchContent = !switchContent">
            <div v-if="switchContent" class="content__switch-icon">
              <EditPen />
            </div>
            <div v-else>
              Готово
            </div>
          </el-button>
        </div>
        <PhoneViewContent v-if="switchContent" :data="data" />
        <PhoneEditContent v-else :key="JSON.stringify(data)" :data="data" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';
import PhoneViewContent from 'features/settings/mobile-editor/components/right-side/module/phone-view-content.vue';
import PhoneEditContent from 'features/settings/mobile-editor/components/right-side/module/phone-edit-content.vue';
import { WidgetsAndTranslates } from 'features/settings/mobile-editor/interfaces';
import { EditPen, ArrowLeft } from '@element-plus/icons-vue';

export default defineComponent({
  name: 'PhoneMock',
  components: {
    PhoneEditContent, PhoneViewContent, EditPen, ArrowLeft, 
  },
  props: {
    data: {
      type: Object as PropType<WidgetsAndTranslates>,
      required: true,
    },
  },
  setup (props) {
    const switchContent = ref<boolean>(true);

    return {
      switchContent,
    };
  },
});
</script>

<style lang="scss">
@import "./assets/scss/iphone.scss";

.content {
  &__switch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      width: auto !important;
    }
    &-icon {
      width: 16px;
    }
  }
}

.content__switch {
  .el-button--text:not(.is-disabled):focus {
    color: var(--el-color-primary);
  }
}

</style>
