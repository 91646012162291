import { AdditionalField, Widgets } from 'features/settings/mobile-editor/interfaces';
import { MetaHubstrFieldsGroup } from 'features/residents/layouts/meta-hubstr';
import { TranslateList } from 'features/settings/mobile-editor/requests';
import { ADMIN_GROUP_TYPES } from 'features/settings/mobile-editor/constants';

// eslint-disable-next-line no-shadow
enum FieldType {
  text = 'input',
  number = 'input',
  link = 'input',
  datetime = 'datetime',
  array = 'select',
}
// eslint-disable-next-line no-shadow
enum DataType {
  text = 'string',
  number = 'number',
  link = 'string',
  datetime = 'date',
  array = 'array',
}

const placeholders = (value: keyof typeof FieldType | string) => {
  switch (value) {
    case 'text':
      return 'Введите информацию';
    case 'number':
      return '10000';
    case 'link':
      return 'https://www.site.com';
    case 'datetime':
      return '01.03.2000';
    default:
      return 'Введите информацию';
  }
};

/**
 * @param mobileView
 * @param additionalList
 * */
const parseForAdmin = (mobileView: Widgets, additionalList: AdditionalField[]) => {
  const body: MetaHubstrFieldsGroup[] = [];

  mobileView.body
    .filter((group) => ![ADMIN_GROUP_TYPES.SOCIAL, ADMIN_GROUP_TYPES.CONTACTS, ADMIN_GROUP_TYPES.COMMUNITIES].includes(group.adminGroupType))
    .forEach((group) => {
      const fieldsGroup: MetaHubstrFieldsGroup = {
        title: TranslateList[0][group.title.replace('lang.', '')] || group.title,
        fields: [],
      };
    
      group.widgets
        .filter((widget) => widget.text !== 'data.additions.userBadge' && widget.text !== 'data.additions.userCity')
        .forEach((widget) => {
          const matchingField = additionalList.find((field: AdditionalField) => Object.keys(widget).some((key) => {
            if (widget[key] && typeof widget[key] === 'string') {
              return widget[key] === field.formCode;
            }
          }));
          if (matchingField) {
            fieldsGroup.fields.push({
              type: widget.hasAutoComplete ? 'select' : matchingField.isMultiline ? 'textarea' : FieldType[matchingField.variableType as keyof typeof FieldType],
              required: matchingField.isAdminRequired,
              name: matchingField.code,
              label: matchingField.description || TranslateList[0][matchingField.code] || `lang.${matchingField.code}`,
              placeholder: placeholders(matchingField.variableType),
              // если isAdminRequired добавляем валидацию
              ...(matchingField.isAdminRequired && {
                validation: [{
                  type: 'empty-required',
                  trigger: 'blur',
                }],
              }),
              ...(widget.hasAutoComplete && {
                remoteMethodSettings: {
                  url: '/api/form/autocomplete',
                  parameter: 'result',
                  type: matchingField.code,
                  multiple: true,
                // valueKey: 'code',
                // labelKey: 'title',
                },
              }),
              // special
              dataType: DataType[matchingField.variableType as keyof typeof FieldType],
              ...(matchingField.variableType === 'datetime' && {
                propsType: 'date',
                propsFormat: 'DD.MM.YYYY',
              }),
            });
          }
        });

      body.push(fieldsGroup);
    });

  return body;
};

export default parseForAdmin;
