<template>
  <div class="list-line">
    <div class="list-line__title">
      <component :is="data.leftIcon" class="list-line__icon" />
      <label for="" class="list-line__label">{{ translate[data.leftText.replace('lang.', '')] || matchingField.description }}</label>
    </div>
    <div class="list-line__text">Текст</div>
  </div>
  <el-divider />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { AdditionalField } from 'features/settings/mobile-editor/interfaces';
import People from '../../../assets/icons/people.svg';
import Peoples from '../../../assets/icons/peoples.svg';
import Pie_chart from '../../../assets/icons/pie_chart.svg';
import Suitcase from '../../../assets/icons/suitcase.svg';
import Folder from '../../../assets/icons/folder.svg';
import Folder_full from '../../../assets/icons/folder_full.svg';

interface ListLine {
  name: string
  type: string
  leftIcon: any
  leftText: string
  rightIcon: string
  rightText: string
  id: string
}

export default defineComponent({
  name: 'ListLine',
  components: {
    People,
    Peoples,
    Pie_chart,
    Suitcase,
    Folder,
    Folder_full,
  },
  props: {
    data: {
      type: Object as PropType<ListLine>,
      required: true,
    },
    translate: {
      type: Object as PropType<any>,
      required: true,
    },
    additionalList: {
      type: Object as PropType<any>,
      required: true,
    },
  },
  setup (props) {
    const matchingField = Object.values(props.additionalList[0]).find((field) => Object.keys(props.data).some((key) => {
      if (props.data[key] && typeof props.data[key] === 'string') {
        return props.data[key]?.includes(field.formCode);
      }
    })) as AdditionalField;

    return {
      matchingField,
    };
  },
});
</script>

<style lang="scss" scoped>
.list-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__title {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  &__icon {
    width: 20px;
  }
  &__text {
    color: var(--el-color-primary)
  }
}
.el-divider--horizontal {
  margin: 15px 0 15px 0;
}
</style>
