import {
  accessField,
  addressField, broadcastLinkField,
  categoriesField,
  dateStartField,
  descriptionField, invitedDepartmentsField, invitedDepartmentsNotRequiredField,
  isRegistrationOpenedField,
  logoField,
  nameField,
  organizersField, reportField, residentsLimitField,
  statusField, typeField,
} from 'features/events/layouts/general';
import { translate } from 'magner';

export const etalonLayout = [
  /** Left column */
  {
    type: 'column',
    props: {
      span: 12,
      xs: 24,
      styles: { 'padding-right': '12px' },
    },
    layout: [
      {
        type: 'row',
        props: {
          elementsGrow: true,
        },
        fields: [nameField],
      },

      /** dateStart-dateEnd in one field */
      {
        type: 'row',
        props: {
          elementsGrow: true,
          class: 'date-picker-row',
        },
        fields: [dateStartField],
      },

      {
        type: 'column',
        props: {
          isPaper: true,
        },
        layout: [
          {
            type: 'column',
            props: {},
            fields: [organizersField],
          },
        ],
      },

      {
        type: 'column',
        props: {
          span: 24,
        },
        fields: [descriptionField, logoField],
      },
    ],
  },

  /** Right column */
  {
    type: 'column',
    props: {
      span: 12,
      xs: 24,
      styles: { 'padding-left': '12px' },
    },
    layout: [
      {
        type: 'column',
        props: {
          isPaper: true,
        },
        fields: [
          addressField,
          accessField,
          {
            type: 'checkbox',
            name: 'type',
            label: translate('hubstr.event.form.type.label'),
            dataType: 'array',
            options: [{
              label: translate('hubstr.event.form.type.opt_main'),
              value: 'main',
            }],
            props: {},
          },
          categoriesField,
          statusField,
        ],
      },
      {
        type: 'column',
        title: translate('hubstr.event.form.titles.registrations'),
        props: {
          isPaper: true,
          titleType: 'heading',
        },
        fields: [
          isRegistrationOpenedField,
          residentsLimitField,
        ],
      },
      {
        type: 'column',
        props: {
          isPaper: true,
        },
        fields: [
          invitedDepartmentsField,
          reportField,
          broadcastLinkField,
          {
            type: 'input',
            name: 'isPublished',
            props: {
              disabled: true,
              hidden: true,
            },
          },
        ],
      },
    ],
  },
];
