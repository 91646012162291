<template>
  <el-form
    ref="formRef"
    :model="createData"
    @change="emitHandler(formRef)"
    @input="emitHandler(formRef)"
  >
    <el-form-item
      label="Название"
      prop="description"
      :rules="[
        { required: true, message: 'Обязательное поле', },
      ]"
    >
      <el-input v-model="createData.description" :maxlength="40" />
    </el-form-item>
    <el-form-item label="Иконка">
      <el-select v-model="formData.leftIcon">
        <el-option
          v-for="option in options"
          :key="option.value"
          :label="option.label"
          :value="option.value"
        >
          <div class="option">
            <component :is="option.value" class="list-line__icon" />
            <span>{{ option.label }}</span>
          </div>
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="Тип данных">
      <el-radio-group v-model="createData.variableType" :disabled="isEdit">
        <el-radio label="text">Строка</el-radio>
        <el-radio label="link">Ссылка</el-radio>
        <el-radio v-if="createData.variableType === 'number'" label="number">Число</el-radio>
        <el-radio v-if="createData.variableType === 'datetime'" label="datetime">Дата</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item>
      <el-checkbox v-model="createData.isUserRequired" label="Обязательное поле для моб. приложения" />
    </el-form-item>
    <el-form-item>
      <el-checkbox v-model="createData.isAdminRequired" label="Обязательное поле для админ. панели" />
    </el-form-item>
    <el-form-item>
      <el-checkbox v-model="formData.hasPrivacy" label="Имеет настройки приватности" />
    </el-form-item>
  </el-form>
</template>

<script lang="ts">
import {
  defineComponent, onMounted, PropType, reactive, ref, watch,
} from 'vue';
import { CreateField } from 'features/settings/mobile-editor/interfaces';
import { TranslateList } from 'features/settings/mobile-editor/requests';
import { FormInstance } from 'element-plus';
import translit from '~/features/settings/mobile-editor/utils/translit';
import people from '../../../right-side/assets/icons/people.svg';
import peoples from '../../../right-side/assets/icons/peoples.svg';
import pie_chart from '../../../right-side/assets/icons/pie_chart.svg';
import suitcase from '../../../right-side/assets/icons/suitcase.svg';
import folder from '../../../right-side/assets/icons/folder.svg';
import folder_full from '../../../right-side/assets/icons/folder_full.svg';

interface ListLine {
  type: 'list_line_widget',
  leftIcon: string,
  leftText: string,
  rightIcon?: any,
  rightText: string,
  id?: string,
  required: boolean,
  hasPrivacy: boolean,
  name?: string,
}

export default defineComponent({
  name: 'ListLine',
  components: {
    people,
    peoples,
    pie_chart,
    suitcase,
    folder,
    folder_full,
  },
  emits: ['input', 'input:createData', 'input:error'],
  props: {
    widgetData: {
      type: Object as PropType<ListLine>,
      default: () => {},
    },
    additionsData: {
      type: Object as PropType<any>,
      default: () => {},
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const formRef = ref<FormInstance>();

    const options = [
      { label: 'People', value: 'people' },
      { label: 'Peoples', value: 'peoples' },
      { label: 'Pie chart', value: 'pie_chart' },
      { label: 'Suitcase', value: 'suitcase' },
      { label: 'Folder', value: 'folder' },
      { label: 'Folder full', value: 'folder_full' },
    ];

    const formData = reactive<ListLine>(props.widgetData || {
      type: 'list_line_widget',
      leftText: '',
      rightText: '',
      leftIcon: 'people',
      required: false,
      hasPrivacy: false,
    });

    const createData = reactive<CreateField>(props.additionsData || {
      code: '',
      variableType: 'text',
      isUserRequired: false,
      isAdminRequired: false,
      format: 'DEFAULT',
      entityType: 'user',
      description: '',
    });

    const fieldBuilder = () => {
      const descriptionTrim = createData.description.trim();

      if (!props.isEdit) {
        createData.code = descriptionTrim ? `${createData.entityType}${translit(descriptionTrim, 'pascal')}` : '';
        formData.rightText = `data.additions.${createData.code}`;
      }

      formData.leftText = descriptionTrim || `lang.${translit(descriptionTrim, 'camel')}`;
      formData.required = createData.isAdminRequired;
    };

    const emitHandler = (formEl: FormInstance | undefined) => {
      if (!formEl) return;

      formEl.validate((valid) => {
        if (valid) {
          // submit
          emit('input:error', false);

          fieldBuilder();
          const outCreateData = reactive({
            ...createData,
            description: createData.description.trim(),
          });

          emit('input', formData);
          emit('input:createData', outCreateData);
          return;
        }

        emit('input:error', true);
      });
    };

    /**
     * Принудительно с большой буквы
     * */
    watch(() => createData.description, (newValue) => {
      createData.description = newValue.charAt(0).toUpperCase() + newValue.slice(1);
    });

    watch(() => createData.variableType, () => {
      if (createData.variableType === 'link') {
        formData.name = 'link';
      }
    });

    // если нет description название берем из переводов
    onMounted(() => {
      if (props.isEdit && !createData.description) {
        createData.description = TranslateList[0][formData.leftText.replace('lang.', '')];
      }
    });

    return {
      formRef,
      formData,
      createData,
      options,
      emitHandler,
    };
  },
});
</script>

<style scoped>
.list-line__icon {
  width: 20px;
}
.option {
  padding: 0;
  display: flex;
  align-items: center;
  gap: 10px;
}
.el-checkbox {
  font-weight: lighter;
}
</style>
