import { AdditionalField, ViewRoot } from 'features/settings/mobile-editor/interfaces';
import { defaultProfile } from 'features/settings/mobile-editor/default-profile';
import parseForAdmin from 'features/settings/mobile-editor/utils/parse-for-admin';
import parseForMobile from 'features/settings/mobile-editor/utils/parse-for-mobile';
import { GROUP_TYPES } from 'features/settings/mobile-editor/constants';
import { request } from '~/utils/request';

export const AdditionalList: AdditionalField[][] = [];
export const TranslateList: Record<string, string>[] = [];

/**
 * Получение всех существующих additional полей
 * */
export const getAdditionalsList = request.custom<any>(async ({ api }) => {
  try {
    const res = await api.get<any>('/api/admin/additional_fields/list?entityType=user');

    AdditionalList.splice(0, res.data?.data.length, res.data?.data);

    return res;
  } catch (err) {
    return { error: err };
  }
});
/**
 * Создание нового дефолтного view(мп)
 * */
const mobileProfileCreate = request.custom(async ({ api, data }) => {
  try {
    const res = await api.post<any>(
      '/api/admin/view',
      { ...data },
    );

    return {
      data: res.data?.data,
    };
  } catch (err) {
    return { error: err };
  }
});

/**
 * Запрашивает view(мп) с бэка. Если view отсуствует, дергает метод создания дефолтного view.
 * */
export const mobileProfileGet = request.card<any>(async ({ api, data, parseError }) => {
  // TODO: Переделать
  const res = await Promise.all([
    api.get<{ data: ViewRoot }>('/api/admin/view?code=user_profile'),
    api.get<any>('/api/admin/translations/list?locale=ru&domain=profile_view'),
  ])
    .then(([view, translate]) => ({ view, translate }));

  TranslateList.splice(0, res.translate?.data.data.length, res.translate?.data.data);

  if (!res.view?.data?.data.id) {
    const resCreate = await mobileProfileCreate(defaultProfile);

    return {
      data: {
        editMobileProfileLayout: {
          id: resCreate.data.id,
          ...resCreate.data?.widgets,
          translate: res.translate?.data.data,
        },
      },
    };
  }

  /**
   * TODO: убрать когда бэк очнется
   * Подгружаем label из additionals list;
   */
  const resAdditionals = await getAdditionalsList('');
  const additionalsObject: Record<string, string> = {};
  resAdditionals.data.data.map((i: any) => {
    additionalsObject[i.code] = i.description;
    return i;
  });
  res?.view.data.data.widgets.body.map((g: any) => {
    g.widgets.map((w: any) => {
      if (w.type === 'list_line_widget' || w.type === 'list_line_raw_action_widget') {
        if (!res.translate?.data.data[w.leftText?.replace('lang.', '')]) {
          res.translate.data.data[w.leftText?.replace('lang.', '')] = additionalsObject[w.rightText?.replace('data.additions.', '')];
        }
      } else if (w.type !== 'company_list_widget') {
        if (!res.translate?.data.data[w.title?.replace('lang.', '')]) {
          res.translate.data.data[w.title?.replace('lang.', '')] = additionalsObject[w.text?.replace('data.additions.', '')];
        }
      }

      return w;
    });

    return g;
  });

  return {
    data: {
      editMobileProfileLayout: {
        id: res.view.data.data.id,
        ...res?.view.data.data.widgets,
        data: {
          id: 'data.id',
          name: 'data.name',
        },
        translate: res.translate?.data.data,
      },
    },
  };
});

/**
 * Обновление view(мп) и сохранение представления для админки
 * */
export const mobileProfileUpdate = request.card(async ({ api, data, parseError }) => {
  const deepCopyPayload = JSON.parse(JSON.stringify(data.data.editMobileProfileLayout));

  // TODO: Переделать
  // Запрашиваем список всех доп.полей
  const additionalFields = await getAdditionalsList('');
  if (additionalFields.error) {
    return { error: additionalFields.error };
  }

  deepCopyPayload.body = deepCopyPayload.body.map((i: {type: string}) => ({
    ...i,
    type: i.type === 'group' ? GROUP_TYPES.GROUP : i.type,
  }));
    
  const res = await Promise.all([
    // Сохраняем view
    api.put(
      '/api/admin/view',
      {
        id: deepCopyPayload.id,
        code: 'user_profile',
        title: 'Профиль пользователя',
        widgets: {
          ...deepCopyPayload,
          // ...defaultProfile.widgets,
        },
      },
    ),
    // Сохраняем admin-view
    api.post(
      '/api/admin/admin-view',
      {
        code: 'user_profile',
        json: {
          ...parseForAdmin(deepCopyPayload, additionalFields.data.data),
        },
      },
    ),

    // Сохраняем редактирование в мобилке
    api.post(
      '/api/admin/admin-view',
      {
        code: 'mp_profile_form',
        json: {
          ...parseForMobile(deepCopyPayload, additionalFields.data.data),
        },
      },
    ),
  ]).then(([view, admin, edit]) => ({ view, admin, edit }));

  if (res.view.error || res.admin.error || res.edit.error) {
    return { error: 'Ошибка' };
  }
    
  return { data: 'ok' };
});

/**
 * Создание нового additional поля
 * */
export const createNewField = request.custom(async ({ api, data, parseError }) => {
  try {
    const res = await api.post<any>(
      '/api/admin/additional_fields/create',
      { ...data },
    );

    // Запрашиваем список всех доп.полей
    const additionalFields = await getAdditionalsList('');
    if (additionalFields.error) {
      return { error: additionalFields.error };
    }

    return {
      data: { res },
    };
  } catch (err) {
    return { error: err };
  }
});

/**
 * Обновление additional поля
 */
export const updateAdditionalField = request.custom(async ({ api, data, parseError }) => {
  try {
    const res = await api.patch<any>(
      '/api/admin/additional_fields/update',
      { ...data },
    );
    return {
      data: { res },
    };
  } catch (err) {
    return { error: err };
  }
});

/**
 * Добавление поля в автокомплит
 * */
export const createAutocompleteField = request.custom(async ({ api, data, parseError }) => {
  try {
    const res = await api.post<any>(
      '/api/admin/autocomplete/values/create',
      { ...data },
    );

    return { data: res };
  } catch (err) {
    return { error: err };
  }
});

/**
 * Удаление поля из списка автокомплитов
 * */
export const removeAutocompleteField = request.custom(async ({ api, data }) => {
  try {
    const res = await api.delete<any>(
      `/api/admin/autocomplete/value/delete?id=${data}`,
    );

    return { data: res };
  } catch (err) {
    return { error: err };
  }
});

/**
 * Получение полей автокомплита
 * */
export const getAutocompleteFields = request.custom(async ({ api, data }) => {
  try {
    const res = await api.get<any>(
      `/api/admin/autocomplete/list?count=1000&page=1&sort[0][id]=createdAt&sort[0][value]=ASC&filters[0][id]=field&filters[0][value]=${data}`,
    );

    return {
      data: { res },
    };
  } catch (err) {
    return { error: err };
  }
});
