<template>
  <div class="info-line-action">
    <InputFlat
      :label="additional?.description || T[data.title.replace('lang.', '')]"
      :required="additional?.isUserRequired"
      :privacy="data?.hasPrivacy"
      hide-input
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import InputFlat from 'features/settings/mobile-editor/components/right-side/components/input-flat.vue';
import { AdditionalList, TranslateList } from 'features/settings/mobile-editor/requests';
import { data } from 'autoprefixer';

interface InfoLineAction {
  name: string
  text: string
  type: string
  title: string
  isShow: string
  infoText: string
  id: string
}

export default defineComponent({
  name: 'InfoLineAction',
  components: { InputFlat },
  props: {
    data: {
      type: Object as PropType<InfoLineAction>,
      required: true,
    },
  },
  setup (props) {
    const T = TranslateList[0];
    const additional = AdditionalList[0].find((a) => props.data.text === a.formCode);

    return {
      T,
      additional,
    };
  },
});
</script>

<style lang="scss" scoped>

</style>
