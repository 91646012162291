import { AdditionalField, WidgetBody, WidgetsAndTranslates } from 'features/settings/mobile-editor/interfaces';
import { ADMIN_GROUP_TYPES, GROUP_TYPES } from 'features/settings/mobile-editor/constants';

// eslint-disable-next-line no-shadow
enum FieldType {
  text = 'text',
  number = 'text',
  link = 'text',
  datetime = 'datePicker',
  array = 'textAutocomplete',
}

const contacts = ['vk', 'telegram', 'instagram', 'facebook'];

/**
 * @param view
 * @param additionalList
 * */
const parseForMobile = (view: WidgetsAndTranslates, additionalList: AdditionalField[]) => {
  // deepCopy что бы избежать сайд эффектов
  const mobileView = JSON.parse(JSON.stringify(view));
  /**
   * Первый раз мы пробегаемся по списку групп и ищем Социальные сети
   * их надо будет впихнуть в раздел контакты =(
   */
  const socials = mobileView.body.filter((group) => group.type === GROUP_TYPES.SOCIAL);

  /**
   * Если группа контактов отсуствует. Создаем ее.
   * (Далее она будет наполнена из socials (если они есть))
   */
  if (socials.length && !mobileView.body.map((g) => g.adminGroupType).includes(ADMIN_GROUP_TYPES.CONTACTS as any)) {
    mobileView.body.push({
      type: 'group',
      adminGroupType: ADMIN_GROUP_TYPES.CONTACTS as 'contacts',
      name: null,
      title: 'lang.contacts',
      widgets: [],
    });
  }

  const newView = mobileView.body
    .filter((group) => group.type !== GROUP_TYPES.SOCIAL)
    .map((group) => {
      const newWidgets = group.widgets.flatMap((widget) => {
        const matchingField = additionalList.find((field: AdditionalField) => Object.keys(widget).some((key) => {
          if (widget[key] && typeof widget[key] === 'string') {
            return widget[key] === field.formCode;
          }
        }));
        /**
         * Эта замечательная конструкция добавляет еще один объект рядом с виджетом.
         * т.к. он необходим при hasPrivacy=true (настройки приватности)
         */
        if (matchingField && widget.hasPrivacy) {
          const out = [{
            type: widget.hasAutoComplete ? 'textAutocomplete' : FieldType[matchingField.variableType as keyof typeof FieldType],
            value: matchingField.hasDisplayType ? `${matchingField.formCode}_raw` : matchingField.formCode,
            title: matchingField.description || `lang.${matchingField.code}`,
            name: `additions[${matchingField.code}]`,
            required: matchingField.isUserRequired,
            multiline: matchingField.isMultiline,
            placeholder: widget.hasAutoComplete ? 'Выберите вариант или введите свой' : 'Введите информацию',
            ...(widget.hasAutoComplete && {
              url: `/api/form/autocomplete?field=${matchingField.code}`,
              validator: {
                type: 'textAutocomplete',
                minValues: 1,
                maxValues: 15,
                canAddUserElements: true,
              },
            }),
            ...((matchingField.format === 'EMPLOYEES' || matchingField.format === 'TURNOVER') && {
              validator: {
                type: 'integer',
                maxValue: 9223372036854775806,
              },
            }),
          },
          ];

          if (matchingField.format === 'EMPLOYEES' || matchingField.format === 'EXPERIENCE' || matchingField.format === 'TURNOVER') {
            out.push({
              type: 'privacyList',
              name: `additions[${matchingField.code}Display]`,
              value: `data.additions.${matchingField.code}Display`,
              title: 'Приватность',
              list: {
                STRICT: 'Показывать',
                RANGE: 'Только диапазон',
                NONE: 'Скрывать',
              },
              default: 'STRICT',
            });
          } else {
            out.push({
              type: 'privacyList',
              name: `fieldsVisibility[${matchingField.code}]`,
              value: `data.fieldsVisibility.${matchingField.code}`,
              title: 'Приватность',
              list: {
                1: 'Показать',
                0: 'Скрыть',
              },
              default: '1',
            });
          }

          if (!Array.isArray(matchingField.mobileValidator)) {
            out.validator = matchingField.mobileValidator;
          }

          return out;
        }

        if (matchingField && !widget.hasPrivacy) {
          const out = [{
            type: widget.hasAutoComplete ? 'textAutocomplete' : FieldType[matchingField.variableType as keyof typeof FieldType],
            value: matchingField.hasDisplayType ? `${matchingField.formCode}_raw` : matchingField.formCode,
            title: matchingField.description || `lang.${matchingField.code}`,
            name: `additions[${matchingField.code}]`,
            required: matchingField.isUserRequired,
            multiline: matchingField.isMultiline,
            placeholder: widget.hasAutoComplete ? 'Выберите вариант или введите свой' : 'Введите информацию',
            ...(widget.hasAutoComplete && {
              url: `/api/form/autocomplete?field=${matchingField.code}`,
              validator: {
                type: 'textAutocomplete',
                minValues: 1,
                maxValues: 15,
                canAddUserElements: true,
              },
            }),
          },
          ];

          if (!Array.isArray(matchingField.mobileValidator)) {
            out.validator = matchingField.mobileValidator;
          }

          return out;
        }

        if (widget.name === 'phone') {
          return {
            type: 'privacyBool',
            name: 'hidePhone',
            value: 'data.fieldsVisibility.phone',
            title: 'Приватность',
            list: {
              0: 'Показать',
              1: 'Скрыть',
            },
            default: widget.hasPrivacy ? '1' : '0',
          };
        }

        if (widget.value === 'data.birthday') {
          return {
            type: 'datePicker',
            name: 'birthday',
            value: 'data.birthday',
            title: 'lang.birthday',
            required: true,
            validator: {
              maxDate: {
                date: '2007-08-17 21:18:49.385714',
                timezone_type: 3,
                timezone: 'Europe/Moscow',
              },
            },
          };
        }

        if (contacts.includes(widget.name as string)) {
          return {
            type: 'text',
            name: widget.name,
            value: `data.contacts.${widget.name}`,
            title: `lang.${widget.name}`,
            placeholder: '@nick или https://...',
            required: false,
            isMultiline: false,
            validator: {
              type: 'text',
              maxLength: 128,
            },
          };
        }
      }).filter((w) => w);

      /**
       * Если группа - контакты, то надо добавить тудой социальные сети. Но телега не должна дублироваться
       */
      if (group.adminGroupType === ADMIN_GROUP_TYPES.CONTACTS && socials.length) {
        socials[0].widgets.map((w) => {
          if (!newWidgets.map((nw) => nw.name).includes(w.name)) {
            newWidgets.push({
              type: 'text',
              name: w.name,
              value: `data.contacts.${w.name}`,
              title: `lang.${w.name}`,
              placeholder: '@nick или https://...',
              required: false,
              isMultiline: false,
              validator: {
                type: 'text',
                maxLength: 128,
              },
            });
          }
        });
      }
      if (group.adminGroupType === ADMIN_GROUP_TYPES.CONTACTS) {
        if (!group.widgets.find((w) => w.name === 'phone')) {
          newWidgets.push({
            type: 'privacyBool',
            name: 'hidePhone',
            value: 'data.fieldsVisibility.phone',
            title: 'Приватность',
            list: {
              0: 'Показать',
              1: 'Скрыть',
            },
            default: '0',
          });
        }
      }

      return {
        ...group,
        /**
       * GROUP_TYPES.GROUP и GROUP_TYPES.SOCIAL конвертируем в group
       */
        type: group.type === GROUP_TYPES.GROUP || group.type === GROUP_TYPES.SOCIAL ? 'group' : group.type,
        fields: newWidgets.filter((w) => w),
      };
    }) as any;

  // Удаляем лишний объект widgets и adminGroupType
  newView.forEach((group: any) => {
    delete group.widgets;
    delete group.adminGroupType;
  });
  // Удаляем GROUP_TYPES.COMMUNITIES
  // TODO: переделать
  const outBody = newView.filter((group: any) => group.type !== GROUP_TYPES.COMMUNITIES);

  const out = {
    name: 'profile_edit',
    action: '/api/residents/profile/save',
    method: 'post',
    fields: [
      {
        type: 'inline',
        fields: [
          {
            type: 'image_upload',
            name: 'avatar',
            imageType: 'resident_avatar',
            required: true,
            value: 'data.avatar',
          },
          {
            type: 'text',
            name: 'firstName',
            value: 'data.firstName',
            title: 'Ваше имя',
            placeholder: 'Введите информацию',
            required: true,
            isMultiline: false,
            validator: {
              type: 'text',
              minLength: 1,
              maxLength: 24,
            },
          },
        ],
      },
      {
        type: 'text',
        name: 'lastName',
        value: 'data.lastName',
        title: 'Ваша фамилия',
        placeholder: 'Введите информацию',
        required: true,
        isMultiline: false,
        validator: {
          type: 'text',
          minLength: 1,
          maxLength: 24,
        },
      },
      ...outBody,
    ],
  };

  if (mobileView.header.some((h) => h.type === 'header_badge_widget')) {
    out.fields.splice(2, 0, {
      type: 'text',
      name: 'additions[userBadge]',
      value: 'data.additions.userBadge',
      title: 'Бейдж',
      placeholder: 'Коротко главное о себе',
      required: false,
      multiline: false,
      validator: {
        type: 'text',
        maxLength: 150,
      },
    });
  }
  return out;
};
export default parseForMobile;
