<template>
  <div class="input-for-edit">
    <div v-if="tags" class="input-for-edit__tags">
      <div>Текст</div>
      <div>Текст</div>
    </div>
    <label for="">{{ label }} {{ required ? '*' : '' }}</label>
    <input
      v-if="!hideInput"
      type="text"
      :placeholder="!tags ? 'Текст' : ''"
      disabled
    >
    <div v-if="privacy" class="input-for-edit__privacy">
      <div class="input-for-edit__privacy-title">Приватность</div>
      <div class="input-for-edit__privacy-show">
        <div>показывать</div>
        <div> > </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'InputFlat',
  props: {
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    privacy: {
      type: Boolean,
      default: false,
    },
    hideInput: {
      type: Boolean,
      default: false,
    },
    multiply: {
      type: Boolean,
      default: false,
    },
    tags: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
.input-for-edit {
  margin-bottom: 10px;
  position: relative;
  label {
    font-size: small;
    font-weight: 500;
  }
  input {
    width: 100%;
    margin-top: 5px;
    border: none;
    border-bottom: 1px solid rgba(141, 136, 136, 0.2);
    padding-bottom: 5px;
  }
  input:disabled {
    background-color: transparent;
  }
  input::placeholder {
    opacity: 0.5;
  }
  &__privacy {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    &-title {
      color: var(--el-color-primary);
      font-size: 12px;
    }
    &-show {
      display: flex;
      justify-content: center;
      gap: 5px;
      font-size: 10px;
      opacity: 0.3;
      font-weight: 100;
    }
  }
  &__tags {
    display: flex;
    position: absolute;
    gap: 10px;
    top: 25px;
    div {
      opacity: 0.5;
      font-size: 12px
    }
    div:before {
      content: '✕';
      color: var(--el-color-primary);
      padding-right: 5px;
    }
  }
}
</style>
