<template>
  <div class="phone-header">
    <el-avatar
      class="phone-header__avatar"
      :size="100"
      :src="avatar"
    />
    <div
      v-if="data.header.find(i => i.type === 'header_tags_widget')"
      class="phone-header__tags"
    >
      {{ tags }}
    </div>
    <h1
      class="phone-header__name_widget"
    >
      Имя Фамилия
    </h1>
    <div
      v-if="data.header.find(i => i.type === 'header_badge_widget')"
      class="phone-header__badge"
    >
      БЭЙДЖ
    </div>
  </div>
  <div class="phone-header__action-buttons">
    <ActionButtons :data="data.header" />
  </div>

  <div
    v-for="view in data.body"
    :key="view"
    class="phone-body"
  >
    <h3
      class="phone-body__title"
    >
      {{ data.translate[view.title.replace('lang.', '')] || view.title }}
    </h3>
    <br>
    <template v-if="view.type === GROUP_TYPES.SOCIAL">
      <SocialButtons :data="view" />
    </template>
    <template v-else>
      <div v-for="field in view.widgets" :key="field.title">
        <component
          :is="TYPE_FIELDS_TO_COMPONENTS[field.type]"
          :key="field.title"
          :data="field"
          :translate="data.translate"
          :additional-list="AdditionalList"
        />
      </div>
    </template>
  </div>
  <br>
  <br>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { Header, WidgetsAndTranslates, Body } from 'features/settings/mobile-editor/interfaces';
import ActionButtons from 'features/settings/mobile-editor/components/right-side/components/action-buttons.vue';
import SocialButtons from 'features/settings/mobile-editor/components/right-side/components/social-buttons.vue';
import TextSection from 'features/settings/mobile-editor/components/right-side/components/fields/phone-view-content/text-section.vue';
import CompanyList from 'features/settings/mobile-editor/components/right-side/components/fields/phone-view-content/company-list.vue';
import ListLine from 'features/settings/mobile-editor/components/right-side/components/fields/phone-view-content/list-line.vue';
import InfoLineAction
  from 'features/settings/mobile-editor/components/right-side/components/fields/phone-view-content/info-line-action.vue';
import ListLineRawAction
  from 'features/settings/mobile-editor/components/right-side/components/fields/phone-view-content/list-line-raw-action.vue';
import Entry from 'features/settings/mobile-editor/components/right-side/components/fields/phone-view-content/entry.vue';
import { GROUP_TYPES, TYPE_FIELDS_TO_COMPONENTS } from 'features/settings/mobile-editor/constants';
import { AdditionalList } from 'features/settings/mobile-editor/requests';

export default defineComponent({
  name: 'PhoneViewContent',
  components: {
    ActionButtons,
    SocialButtons,
    TextSection,
    CompanyList,
    ListLine,
    InfoLineAction,
    ListLineRawAction,
    Entry,
  },
  computed: {
    GROUP_TYPES () {
      return GROUP_TYPES;
    },
    AdditionalList () {
      return AdditionalList;
    },
  },
  props: {
    data: {
      type: Object as PropType<WidgetsAndTranslates>,
      required: true,
    },
  },
  setup (props) {
    const badge = props.data.header.find((i: Header) => i.type === 'header_badge_widget')?.value?.toUpperCase() || 'БЭЙДЖ';
    const tags = props.data.header.find((i: Header) => i.type === 'header_tags_widget')?.tags || 'КОМАНДА КЛУБА';
    const name = props.data.header.find((i: Header) => i.type === 'header_title_widget')?.value || 'НЕТ ИМЕНИ';
    const avatar = props.data.header.find((i: Header) => i.type === 'header_avatar_widget')?.value || '';

    return {
      badge,
      tags,
      name,
      avatar,

      TYPE_FIELDS_TO_COMPONENTS,
    };
  },
});
</script>

<style lang="scss" scoped>
.phone-header {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__name {
    margin-bottom: 0;
    font-weight: 300;
    font-size: 26px;
    text-align: center;
  }
  &__tags {
    font-size: 10px;
    margin-top: 15px;
    color: var(--el-color-warning);
  }
  &__avatar {
    position: relative;
  }
  &__badge {
    color: var(--el-text-color-secondary);
    font-size: 10px;
    margin-top: 5px;
  }
  &__action-buttons {
    padding: 0 10px;
  }
}

.phone-body {
  &__title {
    font-size: 20px;
    font-weight: 400;
    padding-bottom: 10px;
  }
}
</style>
