<template>
  <el-dialog v-model="isOpen" @close="$emit('update:modelValue', false)">
    <template #title>
      <h2>Выберите тип группы</h2>
    </template>
    <template #default>
      <el-form>
        <el-form-item label="Тип группы">
          <el-select
            v-model="formData.adminGroupType"
            class="m-2"
            placeholder="Выбрать тип"
          >
            <el-option
              v-for="item in types"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="Заголовок">
          <el-input v-model="formData.title" :disabled="formData.adminGroupType === ADMIN_GROUP_TYPES.SOCIAL || formData.adminGroupType === ADMIN_GROUP_TYPES.CONTACTS" />
        </el-form-item>
      </el-form>
    </template>
    <template #footer>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-button @click="$emit('update:modelValue', false)">
            Отмена
          </el-button>
        </el-col>
        <el-col :span="12">
          <el-button type="primary" @click="addGroupHandler">
            Добавить
          </el-button>
        </el-col>
      </el-row>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import {
  computed,
  defineComponent, PropType,
  reactive,
  ref,
  watch,
} from 'vue';
import { magnerMessage } from 'magner';
import { GROUP_TYPES, ADMIN_GROUP_TYPES } from 'features/settings/mobile-editor/constants';

export default defineComponent({
  name: 'AddGroupModal',
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    groups: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  emits: ['update:modelValue', 'change'],
  setup (props, { emit }) {
    const isOpen = ref(props.modelValue);

    const formData = reactive({
      title: '',
      type: GROUP_TYPES.GROUP,
      adminGroupType: ADMIN_GROUP_TYPES.GROUP,
      widgets: [],
    });

    const types = computed(() => {
      const out = [{
        label: 'Группа',
        value: ADMIN_GROUP_TYPES.GROUP,
      }];

      if (!props.groups.includes('companies')) {
        out.push({
          label: 'Группа сообществ/компаний',
          value: ADMIN_GROUP_TYPES.COMMUNITIES,
        });
      }
      if (!props.groups.includes('socials')) {
        out.push({
          label: 'Социальные сети',
          value: ADMIN_GROUP_TYPES.SOCIAL,
        });
      }
      if (!props.groups.includes('contacts')) {
        out.push({
          label: 'Контакты',
          value: ADMIN_GROUP_TYPES.CONTACTS,
        });
      }

      return out;
    });

    watch(() => formData.adminGroupType, () => {
      /**
       * Если type === GROUP_TYPES.COMMUNITIES добавляем в группу 'company_list_widget'
       */
      formData.adminGroupType === ADMIN_GROUP_TYPES.COMMUNITIES
        ? formData.widgets.push({
          type: 'company_list_widget',
          widgets: [],
        } as never) : formData.widgets = [];

      /**
       * задаем данные для разных типов групп
       */
      switch (formData.adminGroupType) {
        case ADMIN_GROUP_TYPES.COMMUNITIES:
          formData.adminGroupType = 'companies';
          formData.type = GROUP_TYPES.COMMUNITIES;
          break;
        case ADMIN_GROUP_TYPES.SOCIAL:
          formData.title = 'Социальные сети';
          formData.adminGroupType = 'socials';
          formData.type = GROUP_TYPES.SOCIAL;
          break;
        case ADMIN_GROUP_TYPES.CONTACTS:
          formData.title = 'Контакты';
          formData.adminGroupType = 'contacts';
          formData.type = GROUP_TYPES.GROUP;
          break;
        default:
          formData.title = '';
      }
    });

    /**
     * Принудительно с большой буквы
     * */
    watch(() => formData.title, (newValue) => {
      formData.title = newValue.charAt(0).toUpperCase() + newValue.slice(1);
    });

    const addGroupHandler = () => {
      if (!formData.title) {
        magnerMessage({
          type: 'error',
          message: 'Заполните все поля',
        });
        return;
      }

      emit('change', formData);
      emit('update:modelValue', false);
    };

    watch(() => props.modelValue, (newValue) => {
      isOpen.value = newValue;
      emit('update:modelValue', newValue);
    });

    return {
      isOpen,
      types,
      formData,
      addGroupHandler,

      GROUP_TYPES,
      ADMIN_GROUP_TYPES,
    };
  },
});
</script>

<style scoped>

</style>
