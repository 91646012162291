// eslint-disable-next-line no-shadow
export enum EnumHeaderModules {
  header_avatar_widget = 'Аватарка',
  header_tags_widget = 'Роль в клубе',
  header_badge_widget = 'Бэйдж',
  header_title_widget = 'Имя Фамилия',
  header_group_buttons_widget = 'Группа кнопок',
}

export enum EnumHeaderButtons {
  telegram = 'Telegram',
  phone = 'Позвонить',
  thanks= 'Поблагодарить',
  add_to_contact = 'В контакты',
  link = 'Ссылка',
  vk = 'VK',
  instagram = 'Instagram',
  facebook = 'Facebook'
}

export enum EnumFieldTypes {
  list_line_widget = 'Короткий текст в одну строку',
  text_section_widget = 'Текст',
  array_section_widget = 'Массив Секция',
  company_list_widget = 'Компани лист',
  info_line_action_widget = 'Телефон',
  list_line_raw_action_widget = 'Значение без заголовка',
}

export const GROUP_TYPES = {
  GROUP: 'group_widget',
  COMMUNITIES: 'group_button_widget',
  SOCIAL: 'group_icons_widget',
  CONTACTS: 'admin_group_contacts_widget',
};

export const ADMIN_GROUP_TYPES = {
  GROUP: 'group',
  COMMUNITIES: 'companies',
  SOCIAL: 'socials',
  CONTACTS: 'contacts',
};

export const TYPE_FIELDS_TO_COMPONENTS = {
  text_widget: 'text-field',
  text_section_widget: 'TextSection',
  list_line_widget: 'ListLine',
  company_list_widget: 'CompanyList',
  info_line_action_widget: 'InfoLineAction',
  list_line_raw_action_widget: 'ListLineRawAction',
  entry_widget: 'Entry',
} as const;
