<template>
  <label for="">{{ translate[state.title.replace('lang.', '')] || data.title }}</label>
  <p>{{ data.hasAutoComplete ? 'Текст 1, Текст 2' : 'Текст' }}</p>
  <el-divider />
</template>

<script lang="ts">
import {
  computed, defineComponent, PropType, reactive, 
} from 'vue';
import { AdditionalField } from 'features/settings/mobile-editor/interfaces';
import { formatShortDate } from '~/utils/format-date';

interface TextSection {
  name: any
  text: string
  type: string
  title: string
  id: string
  hasAutoComplete: string
}

export default defineComponent({
  name: 'TextSection',
  props: {
    data: {
      type: Object as PropType<TextSection>,
      required: true,
    },
    translate: {
      type: Object as PropType<any>,
      required: true,
    },
    additionalList: {
      type: Object as PropType<any>,
      required: true,
    },
  },
  setup (props) {
    const state = reactive({
      ...props.data,
      text: props.data.name === 'date' ? formatShortDate(props.data.value.date) : props.data.text,
    });

    const matchingField = Object.values(props.additionalList[0]).find((field) => Object.keys(props.data).some((key) => {
      if (props.data[key] && typeof props.data[key] === 'string') {
        return props.data[key]?.includes(field.formCode);
      }
    })) as AdditionalField;

    return {
      state,
      matchingField,
    };
  },
});
</script>

<style scoped>
label {
  color: #8A8A8F;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.016em;
}
.el-divider--horizontal {
  margin: 0 0 15px 0;
}
</style>
