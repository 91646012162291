<template>
  <div v-for="i in sections" :key="i.type" class="header-items">
    <CollapseWrap
      v-if="i.type === 'header_group_buttons_widget'"
    >
      <template #title>
        {{ EnumHeaderModules[i.type] }}
        <question-tooltip v-if="i.questionIcon" :text="i.textTooltip" />
      </template>
      <template #default>
        <header-buttons-section
          :data="state"
          @update:data="updateHeaderGroupButtons"
        />
      </template>
    </CollapseWrap>

    <div
      v-else
      class="header-items__item"
    >
      <div class="header-items__item-title">
        <div>{{ EnumHeaderModules[i.type] }}</div>
        <question-tooltip v-if="i.questionIcon" :text="i.textTooltip" />
      </div>
      <el-switch
        v-if="i.switched"
        v-model="i.visible"
        size="small"
        @change="onChecked($event, i.type)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent, onMounted, PropType, reactive, watch,
} from 'vue';
import { EnumHeaderModules } from 'features/settings/mobile-editor/constants';
import QuestionTooltip from 'features/settings/mobile-editor/components/left-side/components/question-tooltip.vue';
import CollapseWrap from 'features/settings/mobile-editor/components/left-side/components/collapse-wrap.vue';
import HeaderButtonsSection
  from 'features/settings/mobile-editor/components/left-side/components/header/header-buttons-section.vue';
import { Header, WidgetsAndTranslates } from 'features/settings/mobile-editor/interfaces';

interface Items {
  type: string,
  switched: boolean,
  questionIcon: boolean,
  textTooltip?: string,
  visible?: boolean,
}

export default defineComponent({
  name: 'HeaderModule',
  components: { HeaderButtonsSection, CollapseWrap, QuestionTooltip },
  props: {
    data: {
      type: Object as PropType<WidgetsAndTranslates>,
      required: true,
    },
  },
  emits: ['update:data'],
  setup (props, { emit }) {
    const copy = props.data.header.slice();

    const state = reactive(props.data.header);

    const sections = reactive([
      {
        type: 'header_avatar_widget',
        switched: false,
        questionIcon: false,
      },
      {
        type: 'header_tags_widget',
        switched: true,
        questionIcon: true,
        textTooltip: 'В мобильном приложении выводиться максимум 3 значения, которые назначены через админ панель в детальной резидента',
        visible: false,
        value: 'data.additions.userTags',
      },
      {
        type: 'header_title_widget',
        switched: false,
        questionIcon: false,
      },
      {
        type: 'header_badge_widget',
        switched: true,
        questionIcon: false,
        visible: false,
        value: 'data.additions.userBadge',
      },
      {
        type: 'header_group_buttons_widget',
        switched: false,
        questionIcon: true,
        textTooltip: 'Можно добавить максимум 4 кнопки',
      },
    ]) as Items[];

    /**
     * Добавляет или удаляет элемент
     * */
    const onChecked = (event: boolean, fieldType: string) => {
      if (event) {
        const index = copy.map((item) => item.type).indexOf(fieldType);

        state.splice(index, 0, copy[index] || { type: fieldType });
      } else {
        const index = state.map((item) => item.type).indexOf(fieldType);

        state.splice(index, 1);
      }
    };

    /**
     * Проходит по пропсу и если секция с таким типом найдена в Sections
     * Включает флаг visible в Items
     * */
    onMounted(() => {
      sections.map((item: Items) => {
        state.map((h) => {
          if (item.type === h.type) {
            item.visible = true;
          }
          return true;
        });
        return true;
      });
    });

    /**
     * Обновляет группу кнопок
     * */
    const updateHeaderGroupButtons = (event: Header) => {
      if (!state.find((h) => h.type === 'header_group_buttons_widget')) {
        state.push({
          type: 'header_group_buttons_widget',
          title: '',
          widgets: [],
        });
      }

      state.find((h) => h.type === 'header_group_buttons_widget').widgets = event;
    };

    return {
      EnumHeaderModules,
      state,
      sections,
      onChecked,
      updateHeaderGroupButtons,
    };
  },
});
</script>

<style lang="scss" scoped>
.header-items {
  &__item {
    height: 69px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: var(--el-box-shadow-lighter);
    padding: 10px 20px;
    border-radius: var(--el-card-border-radius);
    margin-bottom: 10px;
  }
  &__item-title {
    display: flex;
    align-items: center;
  }
}
</style>
